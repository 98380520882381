<template>
  <div id="support-screen">

  </div>
</template>

<script>
export default {
  name: 'SupportScreen',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {}
}
</script>
 
<style lang='scss' scoped>
</style>